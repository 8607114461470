<template>
  <div class="flex">
    <div class="col-one">
        <img src="../assets/bg-two.png" alt="">
    </div><!--col-one-->

    <div class="col-two">
        <h2>A maior empresa de intermediação em vendas e consultoria e assessoria de consorcio do Brasil!</h2>
        <h5>Sim, é assim que tratamos nossos franqueados: como sócios. Eles constroem esse negócio com a gente e se você quiser, pode fazer parte disso também.</h5>
    </div><!--col-two-->
  </div><!--flex-->
</template>

<script>
export default {

}
</script>

<style scoped>
    div.col-one{
        width: 47%;
    }

    div.col-one img{
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 10px;
    }

    div.col-two{
        width: 50%;
        text-align: left;
    }

    div.col-two h2{
        color: var(--color-white--);
        font-size: 30px;
        font-family: 'Mulish', sans-serif;
        font-weight: 900;
        margin-bottom: 20px;
    }

    div.col-two h5{
        color: var(--color-white--);
        font-size: 20px;
        font-family: 'Mulish', sans-serif;
        font-weight: 500;
    }

    @media screen and (max-width: 880px) {
        div.col-one{
            width: 100%;
            order: 2;
        }

        div.col-two{
            width: 100%;
            text-align: center;
            order: 1;
            margin-bottom: 30px;
        }
        div.col-two h2{
            font-size: 26px;
        }
        div.col-two h5{
            color: var(--color-white--);
            font-size: 18px;
        }
    }

    @media screen and (max-width: 720px){
        div.col-two h2{
            font-size: 24px;
        }
        div.col-two h5{
            color: var(--color-white--);
            font-size: 18px;
        }
    }

    @media screen and (max-width: 645px){
        div.col-two h2{
            font-size: 22px;
            margin-bottom: 10px;
        }
        div.col-two h5{
            font-size: 16px;
        }
    }

    @media screen and (max-width: 545px){
        div.col-two h2{
            font-size: 20px;
        }
        div.col-two h5{
            font-size: 14px;
        }
    }
</style>