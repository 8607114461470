<template>
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :root{
    --color-pp--: #FF7A00;
    --color-pp-hover--: #d46700;
    --color-white--: white;
    --color-black--: black;
    --color-border--: #CCCCCC;
  }

  html,body{
    background: var(--color-black--);
  }
  /**Framework */
  .container{
    width: 100%;
    max-width: 1240px;
    padding: 0 4%;
    margin: 0 auto;
  }

  .flex{
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  /**Framework */
</style>
