<template>
  <div class="modelo-franquia">
    <div class="flex">
        <div class="col-one">
            <div class="box">
                <div class="subtitle">
                    <span>Escritório</span>
                </div><!--subtitle-->
                <div class="foto">
                    <img src="../assets/img-box.jpg" alt="">
                </div><!--foto-->
                <div class="texto">
                    <h2>Investimento inicial <span>R$100.000,00</span></h2>
                </div><!--texto-->
            </div><!--box-->

            <div class="conteudo">
                <h4>Escritório:</h4>

                <p>Para essa modalidade de franquia o empreendedor precisará de um espaço de no mínimo 150m2 para ocupação de 15 corretores de automóveis, 2 vendedores, 1 supervisor, 1 diretor ou dono da franquia. <br><br>Nisso o espaço será dividido entre sala de agendamentos e sala de negociação.</p>

                <h3>Serviços a ser oferecido aos clientes: </h3>
                <ul>
                    <li>
                        <img src="../assets/checked-icon.svg" alt="">
                        <h5>Consultoria para consórcio</h5>
                    </li>
                    <li>
                        <img src="../assets/checked-icon.svg" alt="">
                        <h5>Consultoria para financiamento</h5>
                    </li>
                    <li>
                        <img src="../assets/checked-icon.svg" alt="">
                        <h5>Assessoria para venda e compra de cartas contempladas</h5>
                    </li>
                    <li>
                        <img src="../assets/checked-icon.svg" alt="">
                        <h5>Assessoria para venda e compra de veículos</h5>
                    </li>
                    <li>
                        <img src="../assets/checked-icon.svg" alt="">
                        <h5>Intermediação de compra e venda de veículos</h5>
                    </li>
                </ul>

                <p>O plano de cobrança de cada serviço é único e será passado para o candidato caso ele seja aprovado pelo nosso Compliance jurídico. </p>
            </div><!--conteudo-->
        </div><!--col-one-->

        <div class="col-one">
            <div class="box">
                <div class="subtitle">
                    <span>Loja</span>
                </div><!--subtitle-->
                <div class="foto">
                    <img src="../assets/img-box-2.jpg" alt="">
                </div><!--foto-->
                <div class="texto">
                    <h2>Investimento inicial <span>R$149.000,00</span></h2>
                </div><!--texto-->
            </div><!--box-->

            <div class="conteudo">
                <h4>Loja:</h4>

                <p>Para essa modalidade de franquia, o empreendedor precisará de um espaço de pelo menos 350m² para acomodar os veículos. A principal diferença é que, nesse formato, o empreendedor recebe os veículos em consignação — ou seja, os veículos expostos na loja não são necessariamente de propriedade do franqueado; ele apenas intermediará as vendas.
                   <br><br> 
                Os veículos serão de proprietários que deixarão seus carros em consignação, o que reduzirá em até 90% o custo de montar uma loja de automóveis. O franqueado poderá ganhar comissões de até 10% sobre o valor de cada veículo vendido.
                    <br><br>
                A franqueadora apoiará o franqueado na captação de veículos para exposição na loja, garantindo que o espaço seja sempre abastecido com opções para os clientes. <br>
                Requisitos de Equipe: O espaço deve comportar pelo menos 20 corretores de automóveis, além de 3 vendedores, 1 supervisor e 1 diretor ou dono da franquia.</p>

                <h3>Serviços a ser oferecido aos clientes: </h3>
                <ul>
                    <li>
                        <img src="../assets/checked-icon.svg" alt="">
                        <h5>Consultoria para consórcio</h5>
                    </li>
                    <li>
                        <img src="../assets/checked-icon.svg" alt="">
                        <h5>Consultoria para financiamento</h5>
                    </li>
                    <li>
                        <img src="../assets/checked-icon.svg" alt="">
                        <h5>Assessoria para venda e compra de cartas contempladas</h5>
                    </li>
                    <li>
                        <img src="../assets/checked-icon.svg" alt="">
                        <h5>Assessoria para venda e compra de veículos</h5>
                    </li>
                    <li>
                        <img src="../assets/checked-icon.svg" alt="">
                        <h5>Intermediação de compra e venda de veículos</h5>
                    </li>
                    <li>
                        <img src="../assets/checked-icon.svg" alt="">
                        <h5>Exposição de veículos na loja, podendo cobrar um valor fixo ou variável antes ou após a venda</h5>
                    </li>
                    
                </ul>

                <p>Cada serviço possui um plano de cobrança específico, que será detalhado ao candidato caso ele seja aprovado pelo nosso Compliance jurídico.</p>
            </div><!--conteudo-->
        </div><!--col-one-->
    </div><!--flex-->

    <div class="btn-action">
        <span>QUERO SER FRANQUEADO</span>
    </div><!--btn-action-->
  </div><!--modelo-franquia-->
</template>

<script>
export default {

}
</script>

<style scoped>
    .modelo-franquia{
        text-align: left;
        display: inline-block;
    }

    .modelo-franquia .flex{
        align-items: flex-start;
    }

    .modelo-franquia .col-one{
        width: calc(100% / 2 - 15px);
        margin-bottom: 20px;
    }

    .modelo-franquia .col-one .box{
        width: 100%;
        text-align: center;
        margin-bottom: 50px;
    }

    .modelo-franquia .col-one .box .subtitle{
        display: inline-block;
        border: 1px solid var(--color-white--);
        text-align: center;
        padding: 8px 15px;
        border-radius: 15px;
        margin-bottom: 20px;
    }

    .modelo-franquia .col-one .box .subtitle span{
        font-family: 'Mulish', sans-serif;
        font-size: 14px;
        color: var(--color-white--);
        font-weight: 900;
    }

    .modelo-franquia .col-one .box .foto{
        width: 100%;
        height: 350px;
        border-radius: 20px;
        margin-bottom: 25px;
    }

    .modelo-franquia .col-one .box .foto img{
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: cover;
    }

    .modelo-franquia .col-one .texto{
        display: inline-block;
        text-align: center;
    }

    .modelo-franquia .col-one .texto h2{
        color: var(--color-white--);
        font-family: 'Mulish', sans-serif;
        font-size: 34px;
        font-weight: 700;
    }

    .modelo-franquia .col-one .texto h2 span{
        display: block;
        font-weight: 900;
        padding: 5px 10px;
        background: #00BF63;
    }

    .modelo-franquia .col-one .conteudo{
        text-align: left;
    }

    .modelo-franquia .col-one .conteudo h4{
        color: var(--color-white--);
        font-size: 24px;
        font-family: 'Mulish', sans-serif;
        font-weight: 900;
        margin-bottom: 15px;
    }

    .modelo-franquia .col-one .conteudo p{
        color: var(--color-white--);
        font-weight: 500;
        font-family: 'Mulish', sans-serif;
        font-size: 20px;
        margin-bottom: 20px;
    }

    .modelo-franquia .col-one .conteudo h3{
        color: var(--color-white--);
        font-size: 24px;
        font-family: 'Mulish', sans-serif;
        font-weight: 900;
        margin-bottom: 15px;
    }

    .modelo-franquia .col-one .conteudo ul{
        list-style-type: none;
        text-align: left;
        margin-bottom: 20px;
    }

    .modelo-franquia .col-one .conteudo ul li{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .modelo-franquia .col-one .conteudo ul li img{
        width: 18px;
        height: auto;
        object-fit: contain;
        position: relative;
        top: 8px;
        margin-right: 15px;
    }

    .modelo-franquia .col-one .conteudo ul li h5{
        color: var(--color-white--);
        font-weight: 800;
        font-family: 'Mulish', sans-serif;
        font-size: 18px;
    }

    .btn-action{
        width: 100%;
        padding: 20px 0px;
        background: var(--color-pp--);
        text-align: center;
        border-radius: 10px;
        transition: .5s;
        cursor: pointer;
    }

    .btn-action:hover{
        background: var(--color-pp-hover--);
    }

    .btn-action span{
        color: var(--color-white--);
        font-family: 'Mulish', sans-serif;
        font-size: 22px;
        font-weight: 900;
    }

    @media screen and (max-width: 890px){
        .modelo-franquia .col-one{
            width: 100%;
        }
    }

    @media screen and (max-width: 690px) {
        .modelo-franquia .col-one .texto h2{
            font-size: 28px;
        }
        .modelo-franquia .col-one .conteudo h4{
            font-size: 22px;
        }
        .modelo-franquia .col-one .conteudo p{
            font-size: 18px;
        }
        .modelo-franquia .col-one .conteudo h3{
            font-size: 22px;
        }
        .btn-action span{
            font-size: 22px;
        }
    }

    @media screen and (max-width: 690px) {
        .modelo-franquia .col-one .box .foto{
            height: 270px;
        }
        .modelo-franquia .col-one .texto h2{
            font-size: 24px;
        }
        .modelo-franquia .col-one .conteudo h4{
            font-size: 20px;
        }
        .modelo-franquia .col-one .conteudo p{
            font-size: 16px;
        }
        .modelo-franquia .col-one .conteudo ul li h5{
            font-size: 16px;
        }
        .modelo-franquia .col-one .conteudo h3{
            font-size: 20px;
        }
        .btn-action span{
            font-size: 20px;
        }
        .modelo-franquia .col-one .box{
            margin-bottom: 30px;
        }
    }
    @media screen and (max-width: 490px) {
        .modelo-franquia .col-one .box .foto{
            height: 240px;
        }
        .modelo-franquia .col-one .texto h2{
            font-size: 20px;
        }
        .modelo-franquia .col-one .conteudo h4{
            font-size: 18px;
        }
        .modelo-franquia .col-one .conteudo p{
            font-size: 14px;
        }
        .modelo-franquia .col-one .conteudo ul li h5{
            font-size: 14px;
        }
        .modelo-franquia .col-one .conteudo h3{
            font-size: 18px;
        }
        .btn-action span{
            font-size: 18px;
        }
    }
</style>