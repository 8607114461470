<template>
  <section class="sessao-one">
    <div class="container">
      <SectionOne/>
    </div><!--container-->
  </section><!--sessao-one-->

  <section class="sessao-two">
    <div class="container">
      <SectionTwo/>
    </div><!--container-->
  </section><!--sessao-two-->

  <section class="sessao-three">
    <div class="header"></div>
    <div class="bg-color">
      <div class="container">
        <SectionThree/>
      </div><!--container-->
      <div class="col-two">
      </div><!--col-two-->
    </div><!--bg-color-->
  </section><!--three-->

  <section class="sessao-four">
    <div class="container">
      <h3>Por que a RBA Consultoria <br>e não outra franquia?</h3>
      <SectionFour/>
    </div><!--container-->
  </section><!--sessao-four-->

  <section class="sessao-five">
    <div class="container">
      <h3>Modelos de Franquia</h3>
      <SectionFive/>
    </div><!--container-->
  </section><!--sessao-five-->
</template>

<script>
  import SectionOne from '@/components/SectionOne.vue';
  import SectionTwo from '@/components/SectionTwo.vue';
  import SectionThree from '@/components/SectionThree.vue';
  import SectionFour from '@/components/SectionFour.vue';
  import SectionFive from '@/components/SectionFive.vue';
  export default {
    components: {
      SectionOne,
      SectionTwo,
      SectionThree,
      SectionFour,
      SectionFive
    }
  }
</script>

<style scoped>
  section.sessao-one{
    width: 100%;
    background: var(--color-black--);
    padding: 60px 0px;
    background-image: url('../assets/bg-one.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    padding-bottom: 100px;
  }

  section.sessao-two{
    width: 100%;
    padding: 40px 0px;
    margin-bottom: 80px;
  }

  section.sessao-three{
    width: 100%;
    height: auto;
    margin-bottom: 80px;
  }

  section.sessao-three div.header{
    width: 100%;
    height: 125px;
    background-image: url('../assets/header-section-three.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
  }

  section.sessao-three .container{
    position: relative;
  }

  section.sessao-three .bg-color{
    width: 100%;
    background: var(--color-pp--);
    padding-bottom: 60px;
    position: relative;
  }
  section.sessao-three div.col-two{
    width: 70%;
    height: 450px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-image: url('../assets/bg-three.png');
    background-repeat: no-repeat;
    background-position: right top;
    background-size: cover;
  }

  section.sessao-four{
    width: 100%;
    margin-bottom: 60px;
  }

  section.sessao-four .container{
    text-align: center;
  }

  section.sessao-four .container h3{
    color: var(--color-white--);
    font-size: 40px;
    font-family: 'Mulish', sans-serif;
    font-weight: 900;
    margin-bottom: 40px;
  }

  section.sessao-five{
    width: 100%;
    margin-bottom: 40px;
  }

  section.sessao-five .container h3{
    color: var(--color-white--);
    font-size: 40px;
    font-family: 'Mulish', sans-serif;
    font-weight: 900;
    margin-bottom: 40px;
    text-align: center;
  }

  @media screen and (max-width: 970px) {
    section.sessao-three div.col-two{
      display: none;
    }
    section.sessao-four .container h3{
    font-size: 36px;
  }
  section.sessao-five .container h3{
    font-size: 36px;
  }
  }

  @media screen and (max-width: 880px) {
    section.sessao-two{
      width: 100%;
      padding: 20px 0px;
    }
    section.sessao-three{
      margin-bottom: 50px;
    }
    section.sessao-four .container h3{
    font-size: 32px;
  }
  section.sessao-five .container h3{
    font-size: 32px;
  }
  }

  @media screen and (max-width: 750px) {
    section.sessao-one{
      padding: 40px 0px;
    }
    section.sessao-three .bg-color{
      padding-bottom: 40px;
    }
    section.sessao-two{
      margin-bottom: 20px;
    }
    section.sessao-four .container h3{
    font-size: 26px;
  }
  section.sessao-five .container h3{
    font-size: 26px;
  }
  }

  @media screen and (max-width: 690px) {
    section.sessao-four{
      width: 100%;
      margin-bottom: 40px;
    }
    section.sessao-five .container h3{
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 650px) {
    section.sessao-four .container h3{
    font-size: 24px;
  }
  }
  @media screen and (max-width: 550px) {
    section.sessao-four .container h3{
    font-size: 22px;
  }
  section.sessao-five .container h3{
    font-size: 22px;
  }
  }
</style>
