<template>
  <div class="flex">
    <div class="col-one">
        <h2>Torne-se dono (a) de um dos nossos escritórios ou lojas </h2>
        <h5>Como dono ou dona de um consultoria da RBA consultoria, você receberá toda a estrutura necessária para empreender em um dos setores que mais dão dinheiro no mundo.</h5>
        <div class="labels">
            <div class="label-interna">
                <img src="../assets/icon-relogio.svg" alt="">
                <h4>PayBack de 8 a 18
                <span>meses em média</span></h4>
            </div><!--label-interna-->
            <div class="line"></div>
            <div class="label-interna">
                <img src="../assets/icon-saco.svg" alt="">
                <h4>Ampla margem
                <span>de lucro</span></h4>
            </div><!--label-interna-->
            <div class="line"></div>
            <div class="label-interna">
                <img src="../assets/icon-money.svg" alt="">
                <h4>Sem limite de
                <span>faturamento</span></h4>
            </div><!--label-interna-->
        </div><!--labels-->
    </div><!--col-one-->
  </div><!--flex-->
</template>

<script>
export default {

}
</script>

<style scoped>
    div.col-one{
        width: 50%;
        position: relative;
        z-index: 10;
    }

    div.col-one h2{
        color: var(--color-white--);
        font-size: 40px;
        font-family: 'Mulish', sans-serif;
        font-weight: 900;
        margin-bottom: 30px;
    }

    div.col-one h5{
        color: var(--color-white--);
        font-size: 24px;
        font-family: 'Mulish',sans-serif;
        font-weight: 500;
        margin-bottom: 30px;
    }

     div.col-one .labels{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
     }
     div.col-one .labels .label-interna{
        display: inline-block;
        text-align: center;
        margin-right: 20px;
     }
     div.col-one .labels .label-interna:last-child{
        margin-right: 0px !important;
     }

     div.col-one .labels .label-interna img{
        width:  auto;
        height: 50px;
        object-fit: contain;
        display: inline-block;
        margin-bottom: 10px;
     }

     div.col-one .labels .label-interna h4{
        color: var(--color-white--);
        font-family: 'Mulish', sans-serif;
        font-size: 20px;
        font-weight: 500;
     }

     div.col-one .labels .label-interna h4 span{
        font-weight: 900;
        display: block;
     }

     div.col-one .labels  .line{
        width: 2px;
        height: 120px;
        background: var(--color-white--);
        margin-right: 20px;
     }

     @media screen and (max-width: 970px) {
        div.col-one{
            width: 100%;
            text-align: center;
        }
        div.col-one .labels{
            justify-content: center;
        }
     }

     @media screen and (max-width: 780px) {
         div.col-one h2{
            font-size: 36px;
            margin-bottom: 20px;
        }

        div.col-one h5{
            color: var(--color-white--);
            font-size: 22px;
        }
        div.col-one .labels .label-interna h4{
            font-size: 20px;
        }
     }

     @media screen and (max-width: 660px){
        div.col-one h2{
            font-size: 30px;
            margin-bottom: 15px;
        }
        div.col-one h5{
            color: var(--color-white--);
            font-size: 20px;
        }
        div.col-one .labels .label-interna h4{
            font-size: 18px;
        }
     }
    @media screen and (max-width: 560px){
        div.col-one h2{
            font-size: 26px;
            margin-bottom: 15px;
        }
        div.col-one h5{
            color: var(--color-white--);
            font-size: 18px;
        }
        div.col-one .labels .label-interna h4{
            font-size: 16px;
        }
        div.col-one .labels .label-interna img{
            height: 40px;
        }
        div.col-one .labels  .line{
            height: 100px;
        }
    }

    @media screen and (max-width: 500px) {
         div.col-one h2{
            font-size: 22px;
            margin-bottom: 15px;
        }
        div.col-one h5{
            color: var(--color-white--);
            font-size: 16px;
        }
        div.col-one .labels .label-interna h4{
            font-size: 14px;
        }
    }

    @media screen and (max-width: 470px){
        div.col-one .labels  .line:last-child{
            margin-right: 0px !important;
        }
         div.col-one .labels .label-interna:last-child{
            margin-top: 20px;
         }
    }
</style>