<template>
  <div class="flex">
    <div class="col-one">
        <div class="logo">
            <img src="../assets/logo-one.svg" alt="">
        </div><!--logo-->

        <div class="informacoes-importantes">
            <h2>Você tem interesse em <span>ser intermediador de compra e venda de veículos</span> e presta serviço de consultoria e assessoria para todo Brasil?!</h2>
            <div class="line"></div>

            <p>Se você sonha em empreender e quer investir seu dinheiro na área mais promissora do Brasil, temos uma proposta pra te fazer.</p>
        </div><!--informacoes-importantes-->
    </div><!--col-one-->

    <div class="col-two">
        <form id="form-initial" @submit="enviarForm">
            <div class="input">
                <input type="text" v-model="nome" placeholder="Nome Completo">
            </div><!--input-->

            <div class="input">
                <input type="text" v-model="email" placeholder="E-mail">
            </div><!--input-->

            <div class="input">
                <input type="text" v-model="telefone" placeholder="Telefone com DDD">
            </div><!--input-->

            <div class="options">
                <select id="atuacao">
                    <option value="" selected disabled hidden>Qual sua atuação hoje?</option>
                    <option>Atuação 1</option>
                </select>
            </div><!--options-->

            <div class="options">
                <select id="assumir-franquia">
                    <option value="" selected disabled hidden>Quando você pretende assumir sua franquia?</option>
                    <option>1 mês</option>
                </select>
            </div><!--options-->

            <div class="options">
                <select id="investimento">
                    <option value="" selected disabled hidden>Quanto tem pra investir?</option>
                    <option>R$1.000,00</option>
                </select>
            </div><!--options-->

            <div class="submit">
                <div class="load"></div>
                <input type="submit" value="QUERO FAZER NEGÓCIO COM A RBA">
            </div><!--submit-->
        </form><!--form-->
    </div><!--col-two-->
  </div><!--flex-->
</template>

<script>
export default {

}
</script>

<style>
    div.col-one{
        width: 50%;
    }

    div.col-one .logo{
        width: 227.42px;
        height: auto;
        margin-bottom: 40px;
    }

    div.col-one .logo img{
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    div.col-one div.informacoes-importantes{
        display: inline-block;
        text-align: left;
    }

    div.col-one div.informacoes-importantes h2{
        color: var(--color-white--);
        font-size: 36px;
        font-family: 'Mulish', sans-serif;
        font-weight: 700;
        margin-bottom: 20px;
    }

    div.col-one div.informacoes-importantes h2 span{
        color: var(--color-pp--);
        font-weight: 900;
    }

    div.col-one div.informacoes-importantes .line{
        width: 30%;
        height: 3px;
        background: var(--color-pp--);
        margin-bottom: 40px;
    }

    div.col-one div.informacoes-importantes p{
        color: var(--color-white--);
        font-size: 24px;
        font-weight: 500;
        font-family: 'Mulish', sans-serif;
    }

    div.col-two{
        width: 40%;
    }

    div.col-two form{
        width: 100%;
        border-radius: 13px;
        background: var(--color-white--);
        padding: 30px 20px;
    }

    div.col-two form .input{
        width: 100%;
        background: var(--color-white--);
        margin-bottom: 25px;
    }

    div.col-two form .input input{
        color: var(--color-black--);
        font-size: 14px;
        font-family: 'Mulish', sans-serif;
        background: none;
        transition: .5s;
        width: 100%;
        padding: 10px;
        outline: none !important;
        border: 1px solid var(--color-border--);
        border-radius: 5px;
    }

    div.col-two form .input input:focus{
        outline: none !important;
        border: 1px solid var(--color-pp--) !important;
    }

    div.col-two form .options{
        width: 100%;
        background: var(--color-white--);
        margin-bottom: 25px;
    }

    div.col-two form .options select{
        color: var(--color-black--);
        font-size: 14px;
        font-family: 'Mulish', sans-serif;
        background: none;
        transition: .5s;
        width: 100%;
        padding: 10px;
        outline: none !important;
        border: 1px solid #969696;
        border-radius: 5px;
        cursor: pointer;
        transition: .5s;
    }

    div.col-two form .options select:hover{
        border-color: var(--color-pp--);
    }

    div.col-two form .options select option{
        color: var(--color-black--);
        font-size: 14px;
        font-family: 'Mulish', sans-serif;
        font-weight: 500;
    }

    div.col-two form .input input::placeholder{
        color: #CDCDCD;
    }

    div.col-two form div.submit{
        width: 100%;
        height: 40px;
        background: var(--color-pp--);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 5px;
        cursor: no-drop;
        transition: .5s;
    }

    div.col-two form div.submit:hover{
        background: var(--color-pp-hover--);
    }

    div.col-two form div.submit input{
        width: 100%;
        height: 100%;
        background: none;
        border: none;
        font-size: 16px;
        font-family: 'Mulish', sans-serif;
        font-weight: 900;
        color: var(--color-white--);
        cursor: pointer;
    }

    @media screen and (max-width: 940px) {
        div.col-one .logo{
            width: 190px;
        }
        div.col-one div.informacoes-importantes h2{
            font-size: 32px;
        }
        div.col-one div.informacoes-importantes p{
            font-size: 22px;
        }
        div.col-two{
            width: 45%;
        }
    }

    @media screen and (max-width: 880px) {
        div.col-one{
            width: 100% !important;
            margin-bottom: 30px;
        }
        div.col-one .logo{
            margin: 0 auto;
            margin-bottom: 40px;
        }
        div.col-one div.informacoes-importantes .line{
            margin: 0 auto;
            margin-bottom: 30px;
            width: 80%;
        }
        div.col-one div.informacoes-importantes{
            text-align: center;
        }
        div.col-two{
            width: 100% !important;
        }
    }

    @media screen and (max-width: 830px){
        div.col-one .logo{
            width: 160px;
            margin-bottom: 20px;
        }
        div.col-one div.informacoes-importantes h2{
            font-size: 26px;
        }
        div.col-one div.informacoes-importantes p{
            font-size: 18px;
        }
        div.col-one div.informacoes-importantes .line{
            margin-bottom: 20px;
        }
    }

    @media screen and (max-width: 750px){
        div.col-one{
            width: 100%;
            margin-bottom: 30px;
        }
        div.col-one div.informacoes-importantes{
            text-align: center;
        }
        div.col-one .logo{
            width: 160px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        div.col-one div.informacoes-importantes .line{
            margin: 0 auto;
            margin-bottom: 20px;
        }
        div.col-two{
            width: 100%;
        }
    }

    @media screen and (max-width: 630px){
        div.col-one .logo{
            width: 130px;
            margin-bottom: 20px;
        }
        div.col-one div.informacoes-importantes h2{
            font-size: 22px;
            margin-bottom: 15px;
        }
        div.col-one div.informacoes-importantes .line{
            margin: 0 auto;
            width: 80%;
            margin-bottom: 20px;
            height: 2px;

        }
        div.col-one div.informacoes-importantes p{
            font-size: 16px;
        }
    }

    @media screen and (max-width: 530px){
        div.col-one .logo{
            width: 110px;
            margin-bottom: 20px;
        }
        div.col-one div.informacoes-importantes h2{
            font-size: 20px;
            margin-bottom: 15px;
        }
        div.col-one div.informacoes-importantes p{
            font-size: 16px;
        }
        div.col-two form div.submit input{
            font-size: 12px;
        }
        div.col-two form .input input{
            font-size: 12px;
        }
        div.col-two form .options select{
            font-size: 12px;
        }
        div.col-two form .options select option{
            color: var(--color-black--);
            font-size: 12px;
        }
    }
</style>