<template>
   <div class="motivos">
        <div class="tabela">
            <div class="coluna header">
                <div class="coluna-pp">
                    <h2>COMPARE VOCÊ MESMO</h2>
                </div><!--coluna-pp-->
                <div class="coluna-two">
                    <h4>CONCORRENTES</h4>
                </div><!--coluna-two-->
                <div class="coluna-three">
                    <img src="../assets/logo-two.svg" alt="">
                </div><!--coluna-three-->
            </div><!--coluna-->
            <div class="coluna">
                <div class="coluna-pp">
                    <h2>Suporte na tratativa com clientes, consultoria trabalhista e até
                    implementação de diretrizes do compilance.</h2>
                </div><!--coluna-pp-->
                <div class="coluna-two">
                    <img src="../assets/x-icon.svg" alt="">
                </div><!--coluna-two-->
                <div class="coluna-three">
                    <img src="../assets/v-icon.svg" alt="">
                </div><!--coluna-three-->
            </div><!--coluna-->
            <div class="coluna">
                <div class="coluna-pp">
                    <h2>Temos soluções que nenhuma outra empresa de consultória tem.</h2>
                </div><!--coluna-pp-->
                <div class="coluna-two">
                    <img src="../assets/x-icon.svg" alt="">
                </div><!--coluna-two-->
                <div class="coluna-three">
                    <img src="../assets/v-icon.svg" alt="">
                </div><!--coluna-three-->
            </div><!--coluna-->
            <div class="coluna">
                <div class="coluna-pp">
                    <h2>Traz oportunidades até você.</h2>
                </div><!--coluna-pp-->
                <div class="coluna-two">
                    <img src="../assets/x-icon.svg" alt="">
                </div><!--coluna-two-->
                <div class="coluna-three">
                    <img src="../assets/v-icon.svg" alt="">
                </div><!--coluna-three-->
            </div><!--coluna-->
            <div class="coluna">
                <div class="coluna-pp">
                    <h2>Temos formação própria para qualificar a rede e trazer mais pessoas qualificadas.</h2>
                </div><!--coluna-pp-->
                <div class="coluna-two">
                    <img src="../assets/x-icon.svg" alt="">
                </div><!--coluna-two-->
                <div class="coluna-three">
                    <img src="../assets/v-icon.svg" alt="">
                </div><!--coluna-three-->
            </div><!--coluna-->
            <div class="coluna">
                <div class="coluna-pp">
                    <h2>Ajudar você no processo de contratação.</h2>
                </div><!--coluna-pp-->
                <div class="coluna-two">
                    <img src="../assets/x-icon.svg" alt="">
                </div><!--coluna-two-->
                <div class="coluna-three">
                    <img src="../assets/v-icon.svg" alt="">
                </div><!--coluna-three-->
            </div><!--coluna-->
            <div class="coluna">
                <div class="coluna-pp">
                    <h2>Influência no instagram com milhões de vizualizações nos anúncios.</h2>
                </div><!--coluna-pp-->
                <div class="coluna-two">
                    <img src="../assets/x-icon.svg" alt="">
                </div><!--coluna-two-->
                <div class="coluna-three">
                    <img src="../assets/v-icon.svg" alt="">
                </div><!--coluna-three-->
            </div><!--coluna-->
            <div class="coluna">
                <div class="coluna-pp">
                    <h2>Auxilia no processo de cobrança, emissão de boletos, gestão financeira
e controle de inadimplência.</h2>
                </div><!--coluna-pp-->
                <div class="coluna-two">
                    <img src="../assets/x-icon.svg" alt="">
                </div><!--coluna-two-->
                <div class="coluna-three">
                    <img src="../assets/v-icon.svg" alt="">
                </div><!--coluna-three-->
            </div><!--coluna-->
        </div><!--tabela-->
   </div><!--motivos-->
</template>

<script>
export default {

}
</script>

<style>
    div.motivos{
        display: inline-block;
        text-align: left;
        border: 1px solid #7D7D7D;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
    }

    div.motivos .tabela{
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    div.motivos .tabela .coluna{
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    div.motivos .tabela .coluna.header .coluna-pp{
        background: #0A0A0A !important;
    }

    div.motivos .tabela .coluna.header .coluna-pp h2{
        font-size: 24px;
    }

    div.motivos .tabela .coluna.header .coluna-two{
        background: #0A0A0A !important;
    }

    div.motivos .tabela .coluna.header .coluna-three{
        background: #DCDCDC;
    }

    div.motivos .tabela .coluna .coluna-pp{
        width: 70%;
        padding: 0px 36px;
        min-height: 90px;
        text-align: left;
        display: flex;
        align-items: center;
    }

    div.motivos .tabela .coluna:nth-child(even) .coluna-pp{
        background: #1F1F1F;
    }

    div.motivos .tabela .coluna:nth-child(odd) .coluna-pp{
        background: black;
    }

    div.motivos .tabela .coluna .coluna-pp h2{
        color: var(--color-white--);
        font-size: 20px;
        font-family: 'Mulish', sans-serif;
        font-weight: 600;
    }

    div.motivos .tabela .coluna .coluna-two{
        width: 15%;
        min-height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #1F1F1F;
    }

    div.motivos .tabela .coluna:nth-child(even) .coluna-two{
        background: #1F1F1F;
    }

    div.motivos .tabela .coluna:nth-child(odd) .coluna-two{
        background: black;
    }

    div.motivos .tabela .coluna:not(.coluna.header) .coluna-two img{
        width: 20%;
        height: auto;
        object-fit: contain;
    }

    div.motivos .tabela .coluna .coluna-two h4{
        font-size: 16px;
        font-family: 'Mulish', sans-serif;
        font-weight: 700;
        color: var(--color-white--);
    }

    div.motivos .tabela .coluna .coluna-three{
        width: 15%;
        min-height: 90px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        background: var(--color-white--);
    }

    div.motivos .tabela .coluna:nth-child(even) .coluna-three{
        background: var(--color-white--)
    }

    div.motivos .tabela .coluna:nth-child(odd) .coluna-three{
        background: #DCDCDC;
    }


    div.motivos .tabela .coluna .coluna-three img{
        width: 75%;
        height: auto;
        object-fit: contain;
        display: inline-block;
    }

    div.motivos .tabela .coluna:not(.coluna.header) .coluna-three img{
        width: 25%;
        height: auto;
        object-fit: contain;
    }

    @media screen and (max-width: 1000px) {
        div.motivos .tabela .coluna.header .coluna-pp h2{
            font-size: 20px;
        }
        div.motivos .tabela .coluna .coluna-two h4{
            font-size: 14px;
        }
        div.motivos .tabela .coluna .coluna-three img{
            width: 60%;
        }
        div.motivos .tabela .coluna .coluna-pp h2{
            font-size: 18px;
        }
    }

    @media screen and (max-width: 880px){
        div.motivos .tabela .coluna.header .coluna-pp h2{
            font-size: 18px;
        }
        div.motivos .tabela .coluna .coluna-two h4{
            font-size: 12px;
        }
        div.motivos .tabela .coluna .coluna-pp h2{
            font-size: 16px;
        }
        div.motivos .tabela .coluna:not(.coluna.header) .coluna-two img{
            width: 20%;
        }
        div.motivos .tabela .coluna:not(.coluna.header) .coluna-three img{
            width: 20%;
        }
        div.motivos .tabela .coluna.header .coluna-pp{
            width: 60%;
        }
        div.motivos .tabela .coluna.header .coluna-two{
            width: 25%;
        }
    }

    @media screen and (max-width: 633px){
        div.motivos .tabela .coluna.header .coluna-pp h2{
            font-size: 16px;
        }
        div.motivos .tabela .coluna .coluna-two h4{
            font-size: 10px;
        }
        div.motivos .tabela .coluna .coluna-pp h2{
            font-size: 14px;
        }
        div.motivos .tabela .coluna:not(.coluna.header) .coluna-two img{
            width: 15%;
        }
        div.motivos .tabela .coluna:not(.coluna.header) .coluna-three img{
            width: 15%;
        }
        div.motivos .tabela .coluna .coluna-three img{
            width: 80%;
        }
    }

    @media screen and (max-width: 480px){
        div.motivos .tabela .coluna.header .coluna-pp h2{
            font-size: 14px;
        }
        div.motivos .tabela .coluna .coluna-two h4{
            font-size: 8px;
        }
        div.motivos .tabela .coluna .coluna-pp h2{
            font-size: 12px;
        }
        div.motivos .tabela .coluna:not(.coluna.header) .coluna-two img{
            width: 30%;
            position: relative;
            left: -8px;
        }
        div.motivos .tabela .coluna:not(.coluna.header) .coluna-three img{
            width: 30%;
        }
        div.motivos .tabela .coluna.header .coluna-pp{
            width: 65%;
        }
        div.motivos .tabela .coluna.header .coluna-two{
            width: 20%;
        }
    }

</style>